<template>
    <main>
        <h1>建立籌款</h1>
        <div class="content">
            <div class="steps-container">
                <button
                    :disabled="currentStep === 3"
                    :class="{ active: currentStep >= 1 }"
                    @click="$router.push({ name: 'newDonationStep1' })"
                >
                    個人資料
                </button>
                <div class="line" :class="{ active: currentStep >= 2 }"></div>
                <button
                    :disabled="currentStep === 3 || currentStep === 1"
                    :class="{ active: currentStep >= 2 }"
                >
                    項目資料
                </button>
                <div class="line" :class="{ active: currentStep >= 3 }"></div>
                <span :class="{ active: currentStep >= 3 }">完成</span>
            </div>
            <router-view></router-view>
        </div>
    </main>
</template>

<script>
// TODO: 这里还没有进行数据绑定，因为后端还没给API，不知道怎么定数据格式
export default {
    data() {
        return {};
    },
    computed: {
        currentStep: function () {
            let name = this.$route.name;
            return parseInt(name.substring(name.length - 1));
        },
    },
    beforeRouteUpdate(to, from, next) {
        console.log(to);
        next();
    },
};
</script>

<style lang="scss" scoped>
main {
    min-height: calc(100vh - 48px);
    overflow: hidden;
    background-image: url("../../assets/img/new-donation-background.png");
    background-position: center;
    background-size: cover;
    h1 {
        font-size: 84px;
        color: $white;
        text-align: center;
        margin-top: 15vh;
        margin-bottom: 32px;
    }
}
.content {
    min-height: 450px;
    width: 80%;
    max-width: 1024px;
    // display: grid;
    // grid-template-columns: 150px 1fr;
    display: flex;
    margin: 0 auto 16px;
    * {
        --gray: rgba(255, 255, 255, 0.5);
        --white: #{$white};
    }
}
.steps-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    width: 90px;
    margin-left: -90px;
    margin-right: 10px;
    button,
    span {
        font-size: 22px;
        color: var(--gray);
        user-select: none;
    }
    button {
        cursor: pointer;
        &:disabled {
            cursor: not-allowed;
        }
    }
    .line {
        height: 100px;
        width: 1px;
        background-color: var(--gray);
        position: relative;
        margin: 8px 0;
        &::before,
        &::after {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            background-color: var(--gray);
            left: -2px;
        }
        &::before {
            top: -5px;
        }
        &::after {
            bottom: -5px;
        }
    }
    .active {
        color: var(--white);
    }
    .active + .line::before {
        background-color: var(--white);
    }
    .active.line,
    .active.line::after {
        background-color: var(--white);
    }
}
@media screen and (max-width: 850px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        min-height: 0;
    }
    .steps-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        button,
        span {
            flex-basis: 65px;
            flex-shrink: 0;
            text-align: center;
            font-size: 16px;
        }
        .line {
            height: 1px;
            flex-grow: 1;
            margin: 0 8px;
            &::before,
            &::after {
                top: -2px;
                bottom: auto;
            }
            &::before {
                left: -5px;
                right: auto;
            }
            &::after {
                right: -5px;
                left: auto;
            }
        }
    }
    main > h1 {
        font-size: 48px;
    }
}
</style>